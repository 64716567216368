import { AxiosResponse } from "axios";
import APIClient  from "./axiosClient"
import { PaginationResponse, ResponseData } from "./types/_public";
import { IDashboard } from "./types/_dashboard";
var api = new APIClient();

const path = '/dashboard';
const dashboardApi = {
  dashboard(params: any):Promise<AxiosResponse<IDashboard, any>> {
    const url = `${path}/media-statistics`
    return api.get(url, params)
  },
}
export default dashboardApi
