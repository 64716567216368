import APIClient from "./axiosClient";

class AuthApi {
  private api: APIClient;
  private path: string;

  constructor() {
    this.api = new APIClient();
    this.path = "/auth";
  }

  login(data: any) {
    const url = `${this.path}/login`;
    return this.api.post(url, data);
  }

  refresh(data: any) {
    const url = `${this.path}/refresh`;
    return this.api.post(url, data);
  }

  logout() {
    const url = `${this.path}/logout`;
    return this.api.post(url, {});
  }

  permissions(params: any = {}) {
    const url = "/permissions/current";
    return this.api.get(url, params);
  }

  profile(id: string | number = "", params: any = {}) {
    const url = `${this.path}/profile`;
    return this.api.get(url, params);
  }
}

export default AuthApi;
