import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import NotificationApi from 'api/notificationApi';
import { formatQueryParams } from "helpers/format";

export const getListNotification = createAsyncThunk("listNotification" , async (params: any = {}) => {
  try{
    const response:any = await NotificationApi.getListNotification(formatQueryParams(params));
    return response;
  }catch (error) {
    return error;
  }
});

