import PropTypes from "prop-types";
import { useEffect } from "react";

import { logoutUser } from "../../store/thunks";

//redux
import { useDispatch } from "react-redux";

import withRouter from "../../Components/Common/withRouter";

const Logout = () => {
    const dispatch = useDispatch<any>();

    // Inside your component
    useEffect(() => {
        dispatch(logoutUser());
    }, [dispatch]);

    return <></>;
};

Logout.propTypes = {
    history: PropTypes.object,
};

export default withRouter(Logout);