import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "Components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const { userPermissions } = useRole();

    const [menuActive, setMenuActive] = useState<string>('DASHBOARD');

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
    }, [history]);

    const menuItems: any = [
        {
            id: "dashboard",
            label: t('Dashboards'),
            icon: <FeatherIcon icon="home" className="icon-dual" />,
            link: "/dashboard",
            click: function (e: any) {
                setMenuActive((prev) => 'DASHBOARD');
                setIsCurrentState((prev) => 'Dashboard');
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'DASHBOARD'),
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
            // subItems: []
        },

        {
            id: "multilevel2",
            label: t('Advertiser'),
            icon: <FeatherIcon icon="tv" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'ADVERTISER');
                setIsCurrentState((prev) => "Advertiser");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'ADVERTISER'),
            isShow: isHavePermissionRole([ROLES_FOR_APP.ADVERTISER_LIST, ROLES_FOR_APP.ADVERTISER_SIGN_UP], userPermissions),
            subItems: [
                {
                    id: "advertiser-sign-up",
                    label: t('Advertiser Sign-up'),
                    link: "/advertiser/advertiser-sign-up",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_SIGN_UP, userPermissions),
                },
                {
                    id: "advertiser",
                    label: t('Advertiser List'),
                    link: "/advertiser/list",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_LIST, userPermissions),
                },
            ],
        },
        
        {
            id: "multilevel3",
            label: t('Campaign'),
            icon: <FeatherIcon icon="codesandbox" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'CAMPAIGN');
                setIsCurrentState((prev) => "Campaign");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'CAMPAIGN'),
            isShow: isHavePermissionRole([ROLES_FOR_APP.CAMPAIGN_LIST, ROLES_FOR_APP.CAMPAIGN_NAME], userPermissions),
            subItems: [
                {
                    id: "campaign-name",
                    label: t('Campaign Create'),
                    link: "/campaign/create",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_NAME, userPermissions),
                },
                {
                    id: "campaign-list",
                    label: t('Campaign List'),
                    link: "/campaign/list",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_LIST, userPermissions),
                },
            ],
        },

        {
            id: "multilevel4",
            label: t('Point'),
            icon: <FeatherIcon icon="tag" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'POINT');
                setIsCurrentState((prev) => "Point");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'POINT'),
            isShow: isHavePermissionRole([ROLES_FOR_APP.POINT, ROLES_FOR_APP.PURCHASE, ROLES_FOR_APP.DEPOSIT_HISTORY, ROLES_FOR_APP.PAYMENT_DETAILS], userPermissions),
            subItems: [
                {
                    id: "purchase",
                    label: t('Purchase'),
                    link: "/point/purchase",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.PURCHASE, userPermissions),
                },
                {
                    id: "deposit-history",
                    label: t('Deposit History'),
                    link: "/point/deposit-history",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.DEPOSIT_HISTORY, userPermissions),
                },
                {
                    id: "payment-details",
                    label: t('Payment Details'),
                    link: "/point/payment-details",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.PAYMENT_DETAILS, userPermissions),
                },
            ],
        },

        {
            id: "multilevel5",
            label: t('Analysis'),
            icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'ANALYSIS');
                setIsCurrentState((prev) => "Analysis");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'ANALYSIS'),
            isShow: isHavePermissionRole([ROLES_FOR_APP.ANALYSIS, ROLES_FOR_APP.GENDER_AGE, ROLES_FOR_APP.KEYWORD], userPermissions),
            subItems: [
                {
                    id: "gender-age",
                    label: t('Gender, Age'),
                    link: "/analysis/gender-age",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.GENDER_AGE, userPermissions),
                },
                {
                    id: "keyword",
                    label: t('Keyword'),
                    link: "/analysis/keyword",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD, userPermissions),
                },
            ],
        },

        {
            id: "multilevel6",
            label: t('Report'),
            icon: <FeatherIcon icon="file-text" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'REPORT');
                setIsCurrentState((prev) => "Report");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'REPORT'),
            isShow: isHavePermissionRole([ROLES_FOR_APP.REPORT, ROLES_FOR_APP.ARTICLE_SNAPSHOT, ROLES_FOR_APP.SUMMARY, ROLES_FOR_APP.EXPORT], userPermissions),
            subItems: [
                {
                    id: "article-snapshot",
                    label: t('Article Snapshot'),
                    link: "/report/article-snapshot",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.ARTICLE_SNAPSHOT, userPermissions),
                },
                {
                    id: "summary",
                    label: t('Summary'),
                    link: "/report/summary",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.SUMMARY, userPermissions),
                },
                {
                    id: "export",
                    label: t('Export'),
                    link: "/report/export",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.EXPORT, userPermissions),
                },
            ],
        },

        {
            id: "multilevel7",
            label: t('Customer Service'),
            icon: <FeatherIcon icon="help-circle" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'CUSTOMER SERVICE');
                setIsCurrentState((prev) => "Customer Service");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'CUSTOMER SERVICE'),
            isShow: isHavePermissionRole([ROLES_FOR_APP.CUSTOMER_SERVICE, ROLES_FOR_APP.HELP, ROLES_FOR_APP.INQUIRY], userPermissions),
            subItems: [
                {
                    id: "help",
                    label: t('Help'),
                    link: "/customer-service/help",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.HELP, userPermissions),
                },
                {
                    id: "inquiry",
                    label: t('Inquiry'),
                    link: "/customer-service/inquiry",
                    isShow: isHavePermissionRole(ROLES_FOR_APP.INQUIRY, userPermissions),
                },
            ],
        },

    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
