//Include Both Helper File with needed methods

import authApi from 'api/authApi'
import { apiError, loginSuccess, logoutUserSuccess, reset_login_flag } from './reducer'
import { removeAccessToken, removeRefreshToken, push } from 'helpers/utils'

export const loginUser = (user: any, history: any, href: string) => async (dispatch: any) => {
    try {
        const authApiNew = new authApi()

        let response = await authApiNew.login({
            email: user.email,
            password: user.password,
            device_id: user.device_id,
        })
        if (response) {
            let finallogin: any = response?.data
            localStorage.setItem('authUser', JSON.stringify(finallogin))
            dispatch(loginSuccess(finallogin))
            history(href)
        }
    } catch (error) {
        dispatch(apiError(error))
    }
}

export const logoutUser = () => async (dispatch: any) => {
    try {
        push('/login')
        localStorage.removeItem('authUser')
        removeAccessToken()
        removeRefreshToken()
        // await authApi.logout();
        dispatch(logoutUserSuccess(true))
    } catch (error) {
        push('/login')
        localStorage.removeItem('authUser')
        removeAccessToken()
        removeRefreshToken()
        // dispatch(apiError(error));
    } finally {
        push('/login')
        localStorage.removeItem('authUser')
        removeAccessToken()
        removeRefreshToken()
    }
}

export const resetLoginFlag = () => async (dispatch: any) => {
    try {
        const response = dispatch(reset_login_flag())
        return response
    } catch (error) {
        dispatch(apiError(error))
    }
}
