import { createSlice } from '@reduxjs/toolkit'
import { getUsers } from './thunk'
import { PaginationResponse, ResponseData } from 'api/types/_public'
import { IUser } from 'api/types/_user'

export interface IState {
    users: (ResponseData<IUser[]> & PaginationResponse) | null
    isUserLoading: boolean
    isUserSuccess: boolean

    allUser: ResponseData<IUser[]> | null

    error: any
}

export const initialState: IState = {
    users: null,
    isUserLoading: false,
    isUserSuccess: false,

    allUser: null,

    error: {},
}

const UserSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //get Users
        builder.addCase(getUsers.pending, (state: IState, action: any) => {
            state.isUserLoading = true
        })
        builder.addCase(getUsers.fulfilled, (state: IState, action: any) => {
            state.users = action.payload.data
            state.isUserSuccess = true
            state.isUserLoading = false
        })
        builder.addCase(getUsers.rejected, (state: IState, action: any) => {
            state.error = action.payload.error || null
            state.isUserSuccess = false
            state.isUserLoading = false
        })
    },
})

export default UserSlice.reducer
