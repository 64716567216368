import { Navigate } from 'react-router-dom'

//Dashboard
import Dashboard from '../pages/Dashboard'

//login
import Login from '../pages/Authentication/Login'

import Logout from '../pages/Authentication/Logout'

// User Profile
import Profile from 'pages/User/Profile'


//Advertiser
import AdvertiserList from '../pages/Advertiser/AdvertiserList'
import AdvertiserSignUp from '../pages/Advertiser/AdvertiserSignUp'

//Campaign
import CampaignList from '../pages/Campaign/CampaignList'
import CampaignName from '../pages/Campaign/CampaignName'

//Point
import Purchase from '../pages/Point/Purchase'
import DepositHistory from '../pages/Point/DepositHistory'
import PaymentDetails from '../pages/Point/PaymentDetails'

//Analysis
import GenderAge from '../pages/Analysis/GenderAge'
import Keyword from '../pages/Analysis/Keyword'

//Report
import ArticleSnapshot from '../pages/Report/ArticleSnapshot'
import Summary from '../pages/Report/Summary'
import Export from '../pages/Report/Export'

//Customer Service
import Help from '../pages/CustomerService/Help'
import Inquiry from '../pages/CustomerService/Inquiry'

const authProtectedRoutes = [

    //Dashboard
    { path: `/index`, component: <Dashboard /> },
    { path: `/dashboard`, component: <Dashboard /> },


    { path: '/user/profile', component: <Profile /> },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: '/',
        exact: true,
        component: <Navigate to="/dashboard" />,
    },
    { path: '*', component: <Navigate to="/dashboard" /> },

    //Advertiser
    { path: `/advertiser/list`, component: <AdvertiserList /> },
    { path: `/advertiser/advertiser-sign-up`, component: <AdvertiserSignUp /> },

    //Campaign
    { path: `/campaign/create`, component: <CampaignName /> },
    { path: `/campaign/list`, component: <CampaignList /> },

    //Point
    { path: `/point/purchase`, component: <Purchase /> },
    { path: `/point/deposit-history`, component: <DepositHistory /> },
    { path: `/point/payment-details`, component: <PaymentDetails /> },

    //Analysis
    { path: `/analysis/gender-age`, component: <GenderAge /> },
    { path: `/analysis/keyword`, component: <Keyword /> },

    //Report
    { path: `/report/article-snapshot`, component: <ArticleSnapshot /> },
    { path: `/report/summary`, component: <Summary /> },
    { path: `/report/export`, component: <Export /> },

    //Customer Service
    { path: `/customer-service/help`, component: <Help /> },
    { path: `/customer-service/inquiry`, component: <Inquiry /> },
]

const publicRoutes = [
    // Authentication Page
    { path: '/logout', component: <Logout /> },
    { path: '/login', component: <Login /> },
]

export { authProtectedRoutes, publicRoutes }
