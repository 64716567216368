import { startOfMonth, endOfMonth, subMonths } from 'date-fns'

import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants'
import { CommaSeparatedNumber, RouteEventProps } from './types'

const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-IN').format(num)
}

const formatNumberWithCommas = (number: number | string = 0) => {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const formatDateStringTo2Row = (date: string = '') => {
    const arr = String(date).split(' ')
    return `${arr[0]} <br/> ${arr[1]}`
}

const formatQueryParams = (query: any = {}) => {
    const clone = structuredClone(query)
    delete clone.time_request
    delete clone.time_request_out
    delete clone.time_request_inner
    return clone
}

const convertToCommaSeparatedNumber: CommaSeparatedNumber = (num) => {
    const numberWithCommas = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return numberWithCommas
}

//---Handle Token

const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN)
const setAccessToken = (token: string) => localStorage.setItem(ACCESS_TOKEN, token)
const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN)

const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN)
const setRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN, token)
const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN)

const createRouteChangeEvent = <T>(routeEventProps: RouteEventProps<T>) => {
    const { path, state, type } = routeEventProps
    return new CustomEvent('route', {
        detail: {
            type,
            path,
            state,
        },
    })
}

const push = <T>(to: string, state?: T) => {
    if (!window) return
    const event = createRouteChangeEvent({ path: to, state, type: 'push' })
    window.dispatchEvent(event)
}

const replace = <T>(to: string, state?: T) => {
    if (!window) return
    const event = createRouteChangeEvent({ path: to, state, type: 'replace' })
    window.dispatchEvent(event)
}

const goBack = () => {
    if (!window) return
    const event = createRouteChangeEvent({ type: 'goBack' })
    window.dispatchEvent(event)
}

const currentDate = new Date()

const getPreviousMonth = () => {
    const firstDayOfPreviousMonth = startOfMonth(subMonths(currentDate, 1))

    const lastDayOfPreviousMonth = endOfMonth(subMonths(currentDate, 1))

    return { firstDayOfPreviousMonth, lastDayOfPreviousMonth }
}

const getCurrentMonth = () => {
    const firstDayOfCurrentMonth = startOfMonth(currentDate)

    const lastDayOfCurrentMonth = endOfMonth(currentDate)

    return { firstDayOfCurrentMonth, lastDayOfCurrentMonth }
}

export {
    formatNumber,
    formatNumberWithCommas,
    formatDateStringTo2Row,
    formatQueryParams,
    convertToCommaSeparatedNumber,
    getAccessToken,
    setAccessToken,
    removeAccessToken,
    getRefreshToken,
    setRefreshToken,
    removeRefreshToken,
    push,
    replace,
    goBack,
    getPreviousMonth,
    getCurrentMonth,
}
