import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationENG from "./locales/en.json";
import translationKR from "./locales/kr.json";
import { KEY_LOCAL_STORAGE, LANGUAGE_LIST } from './helpers/constants'

const { I18N_LANGUAGE } = KEY_LOCAL_STORAGE
const { DEFAULT: DEFAULT_LANGUAGE } = LANGUAGE_LIST

// the translations
const resources = {
  en: {
    translation: translationENG,
  },
  kr: {
    translation: translationKR,
  },
};

const language = localStorage.getItem(I18N_LANGUAGE);
if (!language) {
  localStorage.setItem(I18N_LANGUAGE, DEFAULT_LANGUAGE);
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem(I18N_LANGUAGE) || DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE, // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
