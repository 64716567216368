import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getListNotification } from "./thunk";

export interface IState {
  listNotification: (ResponseData<any[]> & PaginationResponse) | null;
  isNotificationLoading: boolean;
  isNotificationSuccess: boolean;
  error: any;
}

export const initialState: IState = {
  listNotification: null,
  isNotificationLoading: false,
  isNotificationSuccess: false,
  error: {},
};

const NotificationSlice = createSlice({
  name: "Device",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Devices
    builder.addCase(getListNotification.pending, (state: IState, action: any) => {
      state.isNotificationLoading = true;
    });
    builder.addCase(getListNotification.fulfilled, (state: IState, action: any) => {
      state.listNotification = action.payload.data;
      state.isNotificationSuccess = true;
      state.isNotificationLoading = false;
    });
    builder.addCase(getListNotification.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNotificationSuccess = false;
      state.isNotificationLoading = false;
    });
  },
});

export default NotificationSlice.reducer;
