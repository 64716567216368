
import React, { useEffect } from 'react'
import { Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'Components/Common/BreadCrumb'


function CampaignList() {
    const { t, i18n } = useTranslation()

    useEffect(() => {
        document.title = `${t('Purchase')} | NewsHub`
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Purchase')} pageTitle={t('Point')} />
                    <Row>
                        <div className='text-center mt-5'>
                            {t('Under development')}
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CampaignList
