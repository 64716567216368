export * from './layout'

enum KEY_LOCAL_STORAGE {
    I18N_LANGUAGE = 'I18N_LANGUAGE',
}

enum LANGUAGE_LIST {
    DEFAULT = 'kr',
    ENGLISH = 'en',
    KOREA = 'kr',
}

enum LIST_ROUTER {
    INDEX = 'index',
    DASHBOARD = 'dashboard',
    REPORT = 'report',
    SOCIAL = 'social',
    NOTIFICATION = 'notification',
}

const ACCESS_TOKEN = 'access_token'

const REFRESH_TOKEN = 'refresh_token'

const DATE_FORMAT = 'Y-m-d'

export { KEY_LOCAL_STORAGE, LANGUAGE_LIST, LIST_ROUTER, ACCESS_TOKEN, REFRESH_TOKEN, DATE_FORMAT }
