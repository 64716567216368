import { AxiosResponse } from "axios";
import APIClient from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICategory, IPostCategory, IPostCategoryKeywords } from "./types/_category";

var api = new APIClient();

const path = '/category';
const categoryApi = {
  categories(params: any):Promise<AxiosResponse<ResponseData<ICategory[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  allCategoriesType(params: any = {}):Promise<AxiosResponse<ResponseData<ICategory[]>, any>>  {
    const url = `masters/category-types`
    return api.get(url, params)
  },
  allCategoriesByType(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<ICategory[]>, any>> {
    const url = `masters/categories`;
    return api.get(url, params);
  },
  category(id:number | string, params: any = {}):Promise<AxiosResponse<ICategory, any>>{
    const url = `${path}/${id}`
    return api.get(url, params)
  },
  postCategory(dataForm: any) : Promise<AxiosResponse<ResponseData<IPostCategory>, any>>  {
    const url = `/categories`;
    return api.post(url, dataForm)
  },
  postCategoryKeywords(dataForm: any) : Promise<AxiosResponse<ResponseData<IPostCategoryKeywords>, any>>  {
    const url = `/categories/keywords`;
    return api.post(url, dataForm)
  },
  putCategory(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICategory>, any>>  {
    const url = `${path}/${id}`;
    return api.post(url, dataForm)
  },
  deleteCategory(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICategory>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  exportFileCategory(params: any = {}):Promise<AxiosResponse<ICategory, any>>{
    const url = `exports/categories`
    return api.get(url, params)
  },
}

export default categoryApi
