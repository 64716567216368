import { AxiosResponse } from "axios";
import APIClient  from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/partner-notices';
const NotificationApi = {
  getListNotification(params: any):Promise<AxiosResponse<ResponseData<any[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
}
export default NotificationApi
